import mixpanel, { type Dict } from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from '~/constants/env'
import { matchIsBrowser } from '~/helpers/navigator'
import { trackWebmarketerPageView } from '~/third-parties/webmarketer/trackWebmarketer.utils'
import { matchIsNodeProduction, matchIsReleasedVersion } from '~/utils/env'

const TRACKING_EVENTS = {
  PRESS_CLICK: 'Press Click',
  SELECT_SLOT_DAY: 'Select Slot Day',
  SELECT_SLOT_HOUR: 'Select Slot Hour',
  SELECT_SLOT_SKIP: 'Select Slot Skip',
  ACCEPT_TERMS: 'Accept Terms',
  TERMS_LINK_CLICK: 'Terms Link Click',
  PRIVACY_NOTICE_CLICK: 'Privacy Notice Click',
  MODIFY_SLOT_CLICK: 'Modify Slot Click',
  CHECKUP_LOCATION_CLICK: 'Checkup Location Click',
  LOGO_BACK_HOME_CLICK: 'Logo Back Home Click',
  NEED_HELP_PHONE_CLICK: 'Need Help Phone Click',
  HOME_VIDEO_ENDS: 'Home Video Ends',
  HOME_VIDEO_ABORTED: 'Home Video Aborted',
  HOME_VIDEO_ERROR: 'Home Video Error',
  PAYMENT_STRIPE_ERROR: 'Payment Stripe Error',
  SEND_INTERESTED_COMPANY_FORM: 'Send Interested Company Form',
  SEND_CONTACT_US_FORM: 'Send Contact us Form',
  OPEN_BURGER_MENU: 'Open Burger Menu',
  PHOTO_CREDIT_CLICK: 'Photo Credit Click',
  SKIP_HOME_VIDEO: 'Skip Home Video',
  REPLAY_HOME_VIDEO: 'Replay Home Video',
  DOWNLOAD_APP_MODAL_OPEN: 'Download App Modal Open',
  DOWNLOAD_APP_LINK_CLICK: 'Download App Link Click',
  FOOTER_LINKEDIN_CLICK: 'Footer LinkedIn Click',
  FOOTER_INSTAGRAM_CLICK: 'Footer Instagram Click',
  MENU_LINKEDIN_CLICK: 'Menu LinkedIn Click',
  MENU_INSTAGRAM_CLICK: 'Menu Instagram Click',
  EXAM_DETAILS_CLICK: 'Exam Details Click',
  EXAMS_FILTER_CLICK: 'Exams Filter Click',
  DISPLAY_ALL_EXAMS_CLICK: 'Display All Exams Click',
  DOWNLOAD_EXAMS_LIST_CLICK: 'Download Exams List Click',
  DOWNLOAD_BIOMARKERS_LIST_CLICK: 'Download Biomarkers List Click',
  BOOK_A_SLOT_REACHED: 'Book A Slot Reached',
  A_UNIQUE_PLACE_REACHED: 'A Unique Place Reached',
  THE_FOLLOW_UP_LINK_REACHED: 'The Follow Up Link Reached',
  CHECK_UP_LINK_REACHED: 'Check Up Link Reached',
  FOLLOW_UP_MEETING_REACHED: 'Follow Up Meeting Reached',
  LOGO_BACK_HOME_FROM_ACCOUNT_CLICK: 'Logo Back Home From Account Click',
  GIFT_CODE_IS_VALID: 'Gift Code Is Valid',
  GIFT_CODE_IS_INVALID: 'Gift Code Is Invalid'
} as const

export type TrackingEventName = keyof typeof TRACKING_EVENTS

export type TrackingProperties = Dict

if (matchIsBrowser()) {
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: !matchIsReleasedVersion(),
    autotrack: false,
    track_pageview: false,
    opt_out_tracking_by_default: true,
    skip_first_touch_marketing: false,
    ignore_dnt: !matchIsNodeProduction(),
    persistence: 'localStorage'
  })
}

export function trackEvent(
  eventName: TrackingEventName,
  properties?: TrackingProperties
) {
  mixpanel.track(TRACKING_EVENTS[eventName], properties)
}

export function trackPageView(page: string) {
  mixpanel.track_pageview({
    page
  })
  trackWebmarketerPageView()
}

export function optInTracking() {
  mixpanel.opt_in_tracking()
}

export function optOutTracking() {
  mixpanel.opt_out_tracking()
}

export function registerSuperProperties(properties: TrackingProperties) {
  mixpanel.register(properties)
}
